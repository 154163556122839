import { useEffect, useState } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import {
  Alert,
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useWidth } from '../../@iosper/utils/UseWidth';

const DataCollapseDrawer = (props) => {
  const { orden } = props;
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <ToggleButton
        aria-label='expand row'
        value='check'
        onChange={() => {
          setOpen(!open);
        }}
        sx={{ border: 0 }}
        fullWidth={true}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          flexWrap='nowrap'
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          <Typography
            variant='body2'
            align='left'
            gutterBottom={false}
            sx={{ color: '#000', textTransform: 'none' }}
          >
            { orden.numero + ' - ' + orden.fecha }
          </Typography>
        </Grid>
      </ToggleButton>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <List>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Descripción' secondary={orden.descripcionPrestacion} />
            </ListItem>
            <ListItem sx={{ py: 0 }}>
              <ListItemText primary='Prestador' secondary={orden.descripcionPrestador} />
            </ListItem>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

const ConsultarOrdenesTable = ({ ordenes, afiliado }) => {
  const screenWidth = useWidth();

  /// -------------------- DATA GRID ORDENES --------------------------
  const mapOrdenToRow = (orden, index) => ({
    id: index,
    numero: orden.numeroSolicitud,
    fecha: orden.fechaSolicitud,
    descripcionPrestacion: orden.practicas,
    descripcionPrestador: '(' + orden.codigoPrestador + ') ' + orden.nombrePrestador,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: afiliado?.personaFisica?.apellido + ' ' + afiliado?.personaFisica?.nombre + ' (' + afiliado?.personaFisica?.numeroDocumentoString+')',
      flex: 1,
      renderCell: (a) => (
        <DataCollapseDrawer
        orden={a.row}
        />
      ),
    },
    { field: 'numero', headerName: 'Número', width: 110 },
    { field: 'fecha', headerName: 'Fecha', width: 150 },
    { field: 'descripcionPrestacion', headerName: 'Descripción', flex: 1 },
    { field: 'descripcionPrestador', headerName: 'Prestador', flex: 1, hideable: true },
  ];

  return ordenes?.length ? (
    <div style={{ width: '100%' }}>
      <DataGrid
        sx={{
          border: 0,
          '& .MuiDataGrid-cell': {
            py: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F2F5F5',
          },
        }}
        autoHeight={true}
        loading={!ordenes.length}
        rows={ordenes.map((a, index) => mapOrdenToRow(a, index))}
        columns={columns}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        columnVisibilityModel={{
          desplegable: screenWidth === 'xs',
          numero: screenWidth !== 'xs',
          fecha: screenWidth !== 'xs',
          descripcionPrestacion: screenWidth !== 'xs',
          descripcionPrestador: screenWidth !== 'xs',
        }}
        pagination={screenWidth === 'xs' ? null : true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableColumnMenu
      />
    </div>
  ) : (
    <Alert severity='info'>No se han encontrado resultados</Alert>
  );
};

export default ConsultarOrdenesTable;
